import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_teacher_api,
  teachers_list_api,
} from "../../DAL/Teachers/Teachers";
import { useNavigate } from "react-router-dom";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import AddOrUpdateTeacher from "../../components/Teachers/AddOrUpdateTeacher";
import ChangePassword from "../../components/Teachers/ChangePassword";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { s3baseUrl } from "../../config/config";

export default function TeachersList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { socket } = useContentSetting();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [teachersList, setTeachersList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [openPassword, setOpenPassword] = useState(false);

  const handleOpenPasswordPopup = (data) => {
    setSelectedObject(data);
    setOpenPassword(true);
  };

  const handleClosePasswordPopup = () => {
    setOpenPassword(false);
    setSelectedObject({});
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_teachers_list = async () => {
    const result = await teachers_list_api();
    if (result.code === 200) {
      const data = result.teachers_list.map((teacher) => {
        return {
          ...teacher,
          associate_name: teacher.associate?.name,
          table_avatar: {
            src: s3baseUrl + teacher.profile_image,
            alt: teacher.name,
          },
        };
      });
      setTeachersList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleStudentsList = (data) => {
    navigate(`/teachers/students-list/${data._id}`);
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Students List",
      icon: "ph:student-fill",
      handleClick: handleStudentsList,
    },
    {
      label: "Change Password",
      icon: "material-symbols:key-outline",
      handleClick: handleOpenPasswordPopup,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_teacher_api(selectedObject._id);
    if (result.code === 200) {
      const data = teachersList.filter(
        (teacher) => teacher._id !== selectedObject._id
      );
      setTeachersList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_teacher_online_offline = (data) => {
    setTeachersList((old) => {
      return old.map((teacher) => {
        if (teacher._id == data._id) {
          return {
            ...teacher,
            is_online: data.is_online,
          };
        }
        return teacher;
      });
    });
  };

  useEffect(() => {
    get_teachers_list();

    socket.on("teacher_online", (data) => {
      handle_teacher_online_offline(data);
    });
    socket.on("teacher_offline", (data) => {
      handle_teacher_online_offline(data);
    });

    return () => {
      socket.off("teacher_online");
      socket.off("teacher_offline");
    };
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Teachers List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Teacher</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={teachersList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Teacher"
        componentToPassDown={
          <AddOrUpdateTeacher
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            teachersList={teachersList}
            setTeachersList={setTeachersList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this teacher?"}
        handleAgree={handleDelete}
      />

      <ComponentPopup
        openPopup={openPassword}
        setOpenPopup={setOpenPassword}
        title="Change Password"
        componentToPassDown={
          <ChangePassword
            handleClose={handleClosePasswordPopup}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
