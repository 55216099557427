import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { fees_management_by_student_api } from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import { currency_sign, show_proper_words } from "../../utils/constant";
import { Link, useParams } from "react-router-dom";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  {
    id: "currency",
    label: "Total Fee",
    renderData: (row) => {
      const total_amount = row.fees_info.reduce(
        (total, entry) => total + entry.amount,
        0
      );
      return <div>{currency_sign(row.currency) + total_amount}</div>;
    },
  },
  {
    id: "fees_info",
    label: "Fees Info",
    renderData: (row) => {
      return (
        <ul className="fees-info-list">
          {row.fees_info.length > 0 &&
            row.fees_info.map((item) => {
              let { month, amount, year } = item;
              return (
                <li className="mb-1">
                  {`${show_proper_words(month)} ${year} --- ${
                    currency_sign(row.currency) + amount
                  }`}
                </li>
              );
            })}
        </ul>
      );
    },
  },
  {
    id: "fee_status",
    label: "Fee Status",
    renderData: (row) => {
      let fee_status = row.fee_status;
      let class_name = "";
      if (fee_status === "pending") {
        class_name = "bg-danger";
      } else if (fee_status === "submitted") {
        class_name = "bg-primary";
      }
      return (
        <span className={`fee-status ${class_name}`}>
          {show_proper_words(fee_status)}
        </span>
      );
    },
  },
  {
    id: "table_avatar",
    label: "Receipt Slip",
    type: "thumbnail",
    show_online: true,
    className: "reduce-cell-padding",
  },
];

export default function FeesByStudent() {
  const { user_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [feesList, setFeesList] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});

  const get_fees_list = async () => {
    const result = await fees_management_by_student_api(user_id);
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let table_avatar = {
          src: s3baseUrl + request.receipt_slip,
          alt: "Receipt Slip",
        };
        return { ...request, table_avatar };
      });
      setFeesList(data);
      setStudentInfo(result.student);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div class="back-button-box">
                  <Link to={`/students`}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {studentInfo.name +
                    " (" +
                    studentInfo.roll_number_string +
                    ")"}
                </div>
              </div>
              <div className="mb-2 mui-table-heading">
                <h2>Fees List</h2>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={feesList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
