const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = (userInfo) => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Students Reports",
      icon: get_icon("fa-solid fa-list"),
      child_options: [
        {
          title: "Recent Reports",
          path: "/recent-reports",
          icon: get_icon("fa-solid fa-list"),
        },
        {
          title: "All Reports",
          path: "/students-reports",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
    {
      title: "Students",
      icon: get_icon("fas fa-user-graduate"),
      child_options: [
        {
          title: "Students List",
          path: "/students",
          icon: get_icon("fas fa-user-graduate"),
        },
        {
          title: "Class Chat",
          path: "/class-room",
          icon: get_icon("fas fa-users"),
        },
        {
          title: "Leave Requests",
          path: "/leave-requests",
          icon: get_icon("fa-solid fa-person-walking-arrow-right"),
        },
        {
          title: "Students Groups",
          path: "/students-groups",
          icon: get_icon("fas fa-users"),
        },
      ],
    },
    {
      title: "Lesson Books",
      path: "/lesson-books",
      icon: get_icon("fas fa-book-reader"),
    },
    {
      title: "Fees Management",
      path: "/fees-management",
      icon: get_icon("fa-solid fa-comment-dollar"),
    },
    {
      title: "Teachers",
      icon: get_icon("fas fa-chalkboard-teacher"),
      child_options: [
        {
          title: "Teachers List",
          path: "/teachers",
          icon: get_icon("fas fa-chalkboard-teacher"),
        },
        {
          title: "Training Lesson",
          path: "/training-lesson",
          icon: get_icon("fa-solid fa-globe"),
        },
      ],
    },
    {
      title: "General Settings",
      path: "/general-setting",
      icon: get_icon("fa-regular fa-file-lines"),
    },
    {
      title: "Extras",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Personal Notes",
          path: "/personal-notes",
          icon: get_icon("fa-solid fa-book"),
        },
        {
          title: "To Do Lists",
          path: "/to-do-lists",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
  ];

  if (userInfo.has_school) {
    sidebarMenus.splice(
      7,
      0,

      {
        title: "Announcements",
        icon: get_icon("fas fa-bullhorn"),
        child_options: [
          {
            title: "For Me",
            path: "/announcements",
            icon: get_icon("fas fa-bullhorn"),
          },
          {
            title: "Templates",
            path: "/announcement-templates",
            icon: get_icon("fas fa-bullhorn"),
          },
          {
            title: "Announcements",
            path: "/announcements-list",
            icon: get_icon("fas fa-bullhorn"),
          },
        ],
      },
      {
        title: "Pages",
        path: "/web-pages",
        icon: get_icon("fa-regular fa-file-lines"),
      }
    );
  } else {
    sidebarMenus.splice(3, 0, {
      title: "Announcements",
      path: "/announcements",
      icon: get_icon("fas fa-bullhorn"),
    });
  }
  return sidebarMenus;
};
export default sidebarConfig;
